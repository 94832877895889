import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import MyNavbar from "./components/navbar/MyNavbar";
import PrivateRoute from "./components/PrivateRoute";

import SignInPage from "./pages/Auth/SignInPage";
import ForgotPasswordPage from "./pages/Auth/ForgotPasswordPage";
import WebsitesPage from "./pages/Dashboard/WebsitesPage";
import NotFoundPage from "./pages/Auth/NotFoundPage";
import MyProfilePage from "./pages/Profile/MyProfilePage";
import ApifyLeadsPage from "./pages/Dashboard/ApifyLeadsPage";
import SellersPage from "./pages/Dashboard/SellersPage";
import MyLeadsPage from "./pages/Dashboard/MyLeadsPage";
import { UserType } from "./business/enums/UserType";
import AddSellerPage from "./pages/Profile/AddSellerPage";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route
            element={
              <>
                <MyNavbar />
                <Outlet />
              </>
            }
          >
            <Route
              path="/"
              element={<PrivateRoute allowedUserTypes={[UserType.ADMIN]} />}
            >
              <Route path="/" element={<WebsitesPage />} />
            </Route>

            <Route
              path="/websites"
              element={<PrivateRoute allowedUserTypes={[UserType.ADMIN]} />}
            >
              <Route path="/websites" element={<WebsitesPage />} />
            </Route>
            <Route
              path="/apifyLeads"
              element={<PrivateRoute allowedUserTypes={[UserType.ADMIN]} />}
            >
              <Route path="/apifyLeads" element={<ApifyLeadsPage />} />
            </Route>

            <Route
              path="/sellers"
              element={<PrivateRoute allowedUserTypes={[UserType.ADMIN]} />}
            >
              <Route path="/sellers" element={<SellersPage />} />
            </Route>

            <Route
              path="/myLeads"
              element={<PrivateRoute allowedUserTypes={[UserType.SELLER]} />}
            >
              <Route path="/myLeads" element={<MyLeadsPage />} />
            </Route>

            <Route
              path="/myProfile"
              element={
                <PrivateRoute
                  allowedUserTypes={[UserType.ADMIN, UserType.SELLER]}
                />
              }
            >
              <Route path="/myProfile" element={<MyProfilePage />} />
            </Route>
            <Route
              path="/notifications"
              element={
                <PrivateRoute
                  allowedUserTypes={[UserType.ADMIN, UserType.SELLER]}
                />
              }
            ></Route>
            <Route
              path="/addSeller"
              element={<PrivateRoute allowedUserTypes={[UserType.ADMIN]} />}
            >
              <Route path="/addSeller" element={<AddSellerPage />} />
            </Route>
          </Route>
          <Route path="/sign-in" element={<SignInPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
      <ToastContainer autoClose={3000} style={{ zIndex: 10000 }} />
    </>
  );
}

export default App;
