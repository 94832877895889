import { collection, query, orderBy, getDocs } from "firebase/firestore";
import { useState, useEffect } from "react";
import { ApifyLead, Seller } from "../../business/models/Interfaces";
import { db } from "../../firebase.config";
import Spinner from "../../components/Spinner";
import SellersTable from "../../components/table/SellersTable";
import { ApifyLeadStatus } from "../../business/enums/WebsiteEnums";

function SellersPage() {
  const [sellers, setSellers] = useState<Seller[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchSellers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchSellers = async () => {
    try {
      const usersRef = collection(db, "sellers");
      const q = query(usersRef, orderBy("name", "desc"));
      const querySnap = await getDocs(q);
      const sellersData: Seller[] = [];

      const promises = querySnap.docs.map(async (doc) => {
        const data = doc.data();
        const leadsRef = collection(doc.ref, "leads");
        const leadsQuerySnap = await getDocs(leadsRef);
        const leads: ApifyLead[] = [];

        leadsQuerySnap.forEach((leadDoc) => {
          const leadData = leadDoc.data();
          leads.push({
            name: leadData.name,
            street: leadData.street,
            city: leadData.city,
            category: leadData.category,
            phone: leadData.phone,
            status: getLeadStatus(leadData.status),
            notes: leadData.notes,
          });
        });

        sellersData.push({
          name: data.name,
          surname: data.surname,
          email: data.email,
          phone: data.phone,
          registerDate: convertDate(data.registerDate),
          leadsAssigned: leads.length,
          pending: leads.filter(
            (l) => getLeadStatus(l.status) === ApifyLeadStatus.pending
          ).length,
          accepted: data.accepted,
          contactAgain: leads.filter(
            (l) => getLeadStatus(l.status) === ApifyLeadStatus.contactAgain
          ).length,
          denied: leads.filter(
            (l) => getLeadStatus(l.status) === ApifyLeadStatus.denied
          ).length,
          leads,
        });
      });

      await Promise.all(promises);

      setSellers(sellersData);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const getLeadStatus = (status: string): ApifyLeadStatus => {
    if (status.toLowerCase() === "pending") {
      return ApifyLeadStatus.pending;
    }
    if (status.toLowerCase() === "contact again") {
      return ApifyLeadStatus.contactAgain;
    }
    if (status.toLowerCase() === "accepted") {
      return ApifyLeadStatus.accepted;
    }
    return ApifyLeadStatus.denied;
  };

  const convertDate = (timestamp: number): string => {
    const date = new Date(timestamp * 1000);
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    return new Intl.DateTimeFormat("it-IT", options).format(date).toString();
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="home-section">
      <div className="home-content">
        <SellersTable
          tableSellers={sellers ?? []}
          refreshTable={fetchSellers}
        />
      </div>
    </div>
  );
}

export default SellersPage;
