import { useEffect, useState } from "react";
import {
  doc,
  updateDoc,
  deleteDoc,
  collection,
  getDoc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { db } from "../../firebase.config";
import { toast } from "react-toastify";
import { ApifyLeadStatus } from "../../business/enums/WebsiteEnums";
import { ApifyLead } from "../../business/models/Interfaces";
import {
  MagnifyingGlassIcon,
  ChevronUpDownIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import {
  Card,
  CardHeader,
  Input,
  Typography,
  CardBody,
  CardFooter,
  IconButton,
  Chip,
  List,
  ListItem,
  Tooltip,
} from "@material-tailwind/react";
import ApiLeadsNotesModal from "../modals/ApiLeadsNotesModal";
import ConfirmModal from "../modals/ConfirmModal";

const TABLE_HEAD = ["Name", "Street", "Category", "Phone", "Status", ""];
function MyLeadsTable({
  apifyLeads,
  refreshTable,
}: {
  apifyLeads: ApifyLead[];
  refreshTable: () => void;
}) {
  const [leads, setLeads] = useState<ApifyLead[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [editStatus, setEditStatus] = useState<boolean[]>([]);
  const [noteDialogOpen, setNoteDialogOpen] = useState<boolean>(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const [selectedLeadIndex, setSelectedLeadIndex] = useState<number>();
  const leadsPerPage = 10;
  const indexOfLastLead = currentPage * leadsPerPage;
  const indexOfFirstLead = indexOfLastLead - leadsPerPage;

  useEffect(() => {
    setLeads(apifyLeads);
    setEditStatus(new Array(apifyLeads.length).fill(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apifyLeads]);

  const filteredWebsites = searchTerm
    ? leads.filter((lead) =>
        `${lead.name}`.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : leads;

  const currentWebsites = filteredWebsites.slice(
    indexOfFirstLead,
    indexOfLastLead
  );

  const toCamelCase = (inputString: string) => {
    return inputString
      .replace(/[-_\s]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ""))
      .replace(/^\w/, (c) => c.toLowerCase());
  };

  const handleSort = (field: string) => {
    let column = toCamelCase(field);

    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
    if (sortDirection === "asc") {
      leads.sort((a, b) =>
        a[column as keyof ApifyLead]
          ?.toString()
          .localeCompare(b[column as keyof ApifyLead]?.toString())
      );
    } else {
      leads.sort((a, b) =>
        b[column as keyof ApifyLead]
          ?.toString()
          .localeCompare(a[column as keyof ApifyLead]?.toString())
      );
    }
    setLeads(leads);
  };

  const leadStatuses: ApifyLeadStatus[] = [
    ApifyLeadStatus.pending,
    ApifyLeadStatus.contactAgain,
    ApifyLeadStatus.accepted,
    ApifyLeadStatus.denied,
  ];

  const updateLeadStatus = async (status: ApifyLeadStatus, index: number) => {
    try {
      let lead = leads[index];
      if (!lead) {
        return;
      }

      let prevStatus = lead.status;

      const auth = getAuth();
      let currentUser = auth.currentUser?.email;

      const sellerRef = doc(db, "sellers", currentUser ?? "");
      const leadRef = collection(sellerRef, "leads");
      const docRef = doc(leadRef, lead.name);
      await updateDoc(docRef, { status: status });

      const sellerDoc = await getDoc(sellerRef);
      leads[index].status = status;
      if (sellerDoc.exists() && prevStatus === ApifyLeadStatus.accepted) {
        const sellerData = sellerDoc.data();
        let newAccepted = sellerData.accepted - 1;
        await updateDoc(sellerRef, {
          accepted: newAccepted,
        });
      }

      if (status === ApifyLeadStatus.accepted && sellerDoc.exists()) {
        const sellerData = sellerDoc.data();
        let newAccepted = sellerData.accepted + 1;
        await updateDoc(sellerRef, {
          accepted: newAccepted,
        });
      }

      setLeads(leads);
      updateEditStatus(index, false);
      toast.success("Lead status updated");
    } catch (error) {
      toast.error("Error while updating lead status");
    }
  };

  const updateEditStatus = (index: number, open: boolean) => {
    setEditStatus(new Array(apifyLeads.length).fill(false));
    setEditStatus((prevEditStatus) => {
      const newEditStatus = [...prevEditStatus];
      newEditStatus[index] = open;
      return newEditStatus;
    });
  };

  const saveNote = async (note: string) => {
    try {
      if (selectedLeadIndex !== null && selectedLeadIndex !== undefined) {
        const leadIndex = selectedLeadIndex;
        let lead = leads[leadIndex];
        if (!lead) {
          return;
        }

        const auth = getAuth();
        let currentUser = auth.currentUser?.email;

        const sellerRef = doc(db, "sellers", currentUser ?? "");
        const leadRef = collection(sellerRef, "leads");
        const docRef = doc(leadRef, lead.name);
        await updateDoc(docRef, { notes: note });

        leads[leadIndex].notes = note;
        setLeads(leads);

        toast.success("Lead note updated");
      }
    } catch (error) {
      toast.error("Error while updating lead note");
    }
  };

  const deleteAllDenied = async () => {
    try {
      const deniedLeads = leads.filter(
        (lead) => lead.status === ApifyLeadStatus.denied
      );

      const deletePromises = deniedLeads.map(async (lead) => {
        const leadRef = doc(db, "apifyLeads", lead.name);
        await deleteDoc(leadRef);
      });

      await Promise.all(deletePromises);

      refreshTable();
      toast.success("Denied leads deleted");
    } catch (error) {
      toast.error("Error while deleting denied leads");
    }
  };

  const calculateSelectedLeadIndex = (currentIndex: number) => {
    return indexOfFirstLead + currentIndex;
  };
  return (
    <>
      <Card className="h-full w-full">
        <CardHeader floated={false} shadow={false} className="rounded-none">
          <div className="mb-8 flex items-center justify-between gap-8">
            <div>
              <Typography variant="h5" color="blue-gray">
                My leads
              </Typography>
              <Typography color="gray" className="mt-1 font-normal">
                See information about all my leads
              </Typography>
            </div>
          </div>
          <div className="w-full md:w-72">
            <Input
              crossOrigin={null}
              label="Search"
              icon={<MagnifyingGlassIcon className="h-5 w-5" />}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </CardHeader>
        <CardBody className="overflow-scroll px-0">
          <table className="mt-4 w-full min-w-max table-auto text-left">
            <thead>
              <tr>
                {TABLE_HEAD.map((head, index) => (
                  <th
                    key={head}
                    className={`cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50 ${
                      index === TABLE_HEAD.length - 1 ? "last-column" : ""
                    }`}
                    onClick={() => handleSort(head)}
                  >
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="flex items-center justify-between gap-2 font-semibold leading-none opacity-70"
                    >
                      {head}

                      {index !== TABLE_HEAD.length - 1 && (
                        <ChevronUpDownIcon
                          strokeWidth={2}
                          color={`${
                            sortColumn === toCamelCase(head) ? "blue" : "grey"
                          }`}
                          className={`h-4 w-4 ${
                            sortDirection === "asc"
                              ? "transform rotate-180"
                              : ""
                          }`}
                        />
                      )}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currentWebsites.map((row, index) => {
                const isLast = index === leads.length - 1;
                const classes = isLast
                  ? "p-4"
                  : "p-4 border-b border-blue-gray-50";

                return (
                  <tr key={row.name}>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-medium"
                          >
                            {row.name}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {row.street}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {row.category}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {row.phone}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="w-max" style={{ position: "relative" }}>
                        <Chip
                          size="sm"
                          variant="ghost"
                          value={row.status}
                          color={
                            row.status === ApifyLeadStatus.pending
                              ? "amber"
                              : row.status === ApifyLeadStatus.contactAgain
                              ? "blue"
                              : row.status === ApifyLeadStatus.denied
                              ? "red"
                              : "green"
                          }
                          icon={
                            editStatus[index] ? (
                              <ChevronUpIcon />
                            ) : (
                              <ChevronDownIcon />
                            )
                          }
                          onTap={() =>
                            updateEditStatus(index, !editStatus[index])
                          }
                        />
                        {editStatus[index] === true && (
                          <Card
                            color="white"
                            className="w-fit"
                            style={{
                              position: "absolute",
                              top: "100%",
                              left: 0,
                              zIndex: "9999",
                            }}
                          >
                            <List className="w-fit min-w-fit">
                              {leadStatuses
                                .filter((status) => row.status !== status)
                                .map((status) => (
                                  <ListItem
                                    className="w-fit p-0 my-1"
                                    key={status}
                                  >
                                    {row.status !== status && (
                                      <Chip
                                        size="sm"
                                        variant="ghost"
                                        value={status}
                                        color={
                                          status === ApifyLeadStatus.pending
                                            ? "amber"
                                            : status ===
                                              ApifyLeadStatus.contactAgain
                                            ? "blue"
                                            : status === ApifyLeadStatus.denied
                                            ? "red"
                                            : "green"
                                        }
                                        onTap={() =>
                                          updateLeadStatus(status, index)
                                        }
                                      />
                                    )}
                                  </ListItem>
                                ))}
                            </List>
                          </Card>
                        )}
                      </div>
                    </td>
                    <td className={classes}>
                      <Tooltip content="Add Notes">
                        <IconButton
                          variant="text"
                          onClick={() => {
                            const selectedLeadIndex =
                              calculateSelectedLeadIndex(index);
                            setSelectedLeadIndex(selectedLeadIndex);
                            setNoteDialogOpen(true);
                          }}
                        >
                          <PencilIcon className="h-4 w-4" />
                        </IconButton>
                      </Tooltip>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CardBody>
        <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
          <Typography variant="small" color="blue-gray" className="font-normal">
            Page {currentPage} of{" "}
            {Math.ceil(filteredWebsites.length / leadsPerPage)}
          </Typography>
          <div className="flex gap-2">
            <IconButton
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <ChevronLeftIcon className="h-4 w-4" />
            </IconButton>
            <IconButton
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={indexOfLastLead >= filteredWebsites.length}
            >
              <ChevronRightIcon className="h-4 w-4" />
            </IconButton>
          </div>
        </CardFooter>
      </Card>
      <ApiLeadsNotesModal
        note={
          selectedLeadIndex !== null && selectedLeadIndex !== undefined
            ? leads[selectedLeadIndex].notes
            : ""
        }
        open={noteDialogOpen}
        onClose={() => setNoteDialogOpen(false)}
        onConfirm={(message: string) => {
          setNoteDialogOpen(false);
          saveNote(message);
        }}
      />
      <ConfirmModal
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        onConfirm={() => {
          setConfirmDialogOpen(false);
          deleteAllDenied();
        }}
      />
    </>
  );
}

export default MyLeadsTable;
