import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Button,
  Textarea,
} from "@material-tailwind/react";
import { useEffect, useState } from "react";

function ApiLeadsNotesModal({
  note,
  open,
  onClose,
  onConfirm,
}: {
  note: string;
  open: boolean;
  onClose: () => void;
  onConfirm: (note: string) => void;
}) {
  const [newNote, setNewNote] = useState<string>(note);

  useEffect(() => {
    setNewNote(note);
  }, [note]);

  const handleTextareaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setNewNote(event.target.value);
  };

  return (
    <Dialog
      open={open}
      handler={() => {
        setNewNote(note);
        onClose();
      }}
      animate={{
        mount: { scale: 1, y: 0 },
        unmount: { scale: 0.9, y: -100 },
      }}
    >
      <div className="flex items-center justify-between">
        <DialogHeader>Add Note</DialogHeader>
      </div>
      <DialogBody divider>
        <div className="grid gap-6">
          <Textarea
            label="Note"
            value={newNote}
            onChange={handleTextareaChange}
          />
        </div>
      </DialogBody>
      <DialogFooter className="space-x-2">
        <Button
          variant="outlined"
          color="red"
          onClick={() => {
            setNewNote(note);
            onClose();
          }}
        >
          Close
        </Button>
        <Button
          variant="gradient"
          color="green"
          onClick={() => {
            onConfirm(newNote);
            setNewNote("");
          }}
        >
          Save Note
        </Button>
      </DialogFooter>
    </Dialog>
  );
}

export default ApiLeadsNotesModal;
