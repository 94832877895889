import { useEffect, useState, useRef } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { UserType } from "../business/enums/UserType";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase.config";

export default function useAuthStatus() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [userType, setUserType] = useState<UserType>();
  const [checkingStatus, setCheckingStatus] = useState(true);
  const isMounted = useRef(true);

  useEffect(() => {
    if (isMounted) {
      const auth = getAuth();
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          const userType = await getUserType(user.email!);
          setUserType(userType);
          setLoggedIn(true);
        }
        setCheckingStatus(false);
      });
    }

    return () => {
      isMounted.current = false;
    };
  }, [isMounted]);

  const getUserType = async (currentEmail: string): Promise<UserType> => {
    const adminsRef = collection(db, "admins");
    const adminQuery = query(adminsRef, where("email", "==", currentEmail));
    const adminQuerySnap = await getDocs(adminQuery);
    if (!adminQuerySnap.empty) {
      return UserType.ADMIN;
    }

    return UserType.SELLER;
  };

  return { loggedIn, checkingStatus, userType };
}
