import {
  Button,
  Dialog,
  Card,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Select,
  Option,
} from "@material-tailwind/react";
import { useState } from "react";
import {
  ClientInterface,
  WebsiteInterface,
} from "../../business/models/Interfaces";
import { PlanStatus, WebsiteStatus } from "../../business/enums/WebsiteEnums";
import industriesFile from "../../assets/json/industries.json";
import { toast } from "react-toastify";

function AddWebsiteModal({
  open,
  onClose,
  onConfirm,
}: {
  open: boolean;
  onClose: () => void;
  onConfirm: (client: ClientInterface, website: WebsiteInterface) => void;
}) {
  const [formData, setFormData] = useState({
    clientName: "",
    clientSurname: "",
    clientEmail: "",
    domain: "",
    industry: "",
    selectedPlan: "",
    websiteStatus: WebsiteStatus.inReview,
    startDate: Math.floor(Date.now() / 1000),
  });
  const {
    clientName,
    clientSurname,
    clientEmail,
    domain,
    industry,
    selectedPlan,
  } = formData;

  const onChange = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const handleSelectChange = (fieldName: string, selectedValue: string) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: selectedValue,
    }));
  };

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const onSubmit = () => {
    if (
      clientName !== null &&
      clientName !== undefined &&
      clientSurname !== null &&
      clientSurname !== undefined &&
      clientEmail !== null &&
      clientEmail !== undefined &&
      domain !== null &&
      domain !== undefined &&
      industry !== null &&
      industry !== undefined &&
      selectedPlan !== null &&
      selectedPlan !== undefined
    ) {
      if (isValidEmail(clientEmail)) {
        let client: ClientInterface = {
          name: clientName,
          surname: clientSurname,
          email: clientEmail,
        };
        let website: WebsiteInterface = {
          industry,
          selectedPlan,
          websiteStatus: formData.websiteStatus,
          subStartDate: formData.startDate,
          domain,
        };
        onClose();
        onConfirm(client, website);
      } else {
        toast.error("Email format invalid");
      }
    } else {
      toast.error("Fill in all the fields");
    }
  };

  return (
    <Dialog
      size="lg"
      open={open}
      handler={onClose}
      className="bg-transparent shadow-none"
    >
      <Card className="mx-auto w-full">
        <CardBody className="flex flex-col gap-4">
          <Typography variant="h4" color="blue-gray">
            Add new website
          </Typography>

          <div className="flex space-x-3">
            <Input
              id="clientName"
              label="Client Name"
              size="lg"
              crossOrigin={undefined}
              value={clientName}
              onChange={onChange}
            />

            <Input
              id="clientSurname"
              label="Client Surname"
              size="lg"
              crossOrigin={undefined}
              value={clientSurname}
              onChange={onChange}
            />
          </div>

          <Input
            id="clientEmail"
            label="Email"
            size="lg"
            crossOrigin={undefined}
            value={clientEmail}
            onChange={onChange}
          />

          <Input
            id="domain"
            label="Domain"
            size="lg"
            crossOrigin={undefined}
            value={domain}
            onChange={onChange}
          />

          <div className="flex space-x-3">
            <Select
              color="blue"
              label="Industry"
              value={industry}
              onChange={(e) => handleSelectChange("industry", e!)}
            >
              {industriesFile.map((industry, index) => (
                <Option key={index} value={industry.name}>
                  {industry.name}
                </Option>
              ))}
            </Select>
            <Select
              color="blue"
              label="Selected Plan"
              value={selectedPlan}
              onChange={(e) => handleSelectChange("selectedPlan", e!)}
            >
              {Object.values(PlanStatus).map((status) => (
                <Option key={status} className="my-1" value={status}>
                  {status}
                </Option>
              ))}
            </Select>
          </div>
        </CardBody>
        <CardFooter className="pt-0">
          <Button variant="gradient" onClick={onSubmit} fullWidth>
            Submit
          </Button>
        </CardFooter>
      </Card>
    </Dialog>
  );
}

export default AddWebsiteModal;
