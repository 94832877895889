import { createRef, useEffect, useRef, useState } from "react";
import {
  getFirestore,
  doc,
  setDoc,
  collection,
  getDoc,
} from "firebase/firestore";
import { ApifyLeadStatus } from "../../business/enums/WebsiteEnums";
import { ApifyLead, Seller } from "../../business/models/Interfaces";
import {
  MagnifyingGlassIcon,
  ChevronUpDownIcon,
  ArrowUpTrayIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import {
  Card,
  CardHeader,
  Input,
  Typography,
  CardBody,
  CardFooter,
  IconButton,
  Chip,
  Button,
  Tooltip,
} from "@material-tailwind/react";
import Spinner from "../Spinner";
import { toast } from "react-toastify";
import ConfirmModal from "../modals/ConfirmModal";

const TABLE_HEAD = [
  "Seller",
  "Phone",
  "Register Date",
  "Leads Assigned",
  "Pending",
  "Accepted",
  "Contact Again",
  "Denied",
  "",
  "",
];

function SellersTable({
  tableSellers,
  refreshTable,
}: {
  tableSellers: Seller[];
  refreshTable: () => void;
}) {
  const [sellers, setSellers] = useState<Seller[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const sellersPerPage = 10;
  const indexOfLastSeller = currentPage * sellersPerPage;
  const indexOfFirstSeller = indexOfLastSeller - sellersPerPage;
  const fileInputRefs = useRef(
    tableSellers.map(() => createRef<HTMLInputElement>())
  );

  useEffect(() => {
    setSellers(tableSellers);
  }, [tableSellers]);

  const filteredSellers = searchTerm
    ? sellers.filter((seller) =>
        `${seller.surname}`.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : sellers;

  const currentSellers = filteredSellers.slice(
    indexOfFirstSeller,
    indexOfLastSeller
  );

  const toCamelCase = (inputString: string) => {
    return inputString
      .replace(/[-_\s]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ""))
      .replace(/^\w/, (c) => c.toLowerCase());
  };

  const handleSort = (field: string) => {
    let column = toCamelCase(field);

    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
    if (sortDirection === "asc") {
      sellers.sort((a, b) =>
        a[column as keyof Seller]
          ?.toString()
          .localeCompare(b[column as keyof Seller]?.toString())
      );
    } else {
      sellers.sort((a, b) =>
        b[column as keyof Seller]
          ?.toString()
          .localeCompare(a[column as keyof Seller]?.toString())
      );
    }
    setSellers(sellers);
  };

  const handleUploadJson = async (seller: Seller, fileInputRef: any) => {
    if (
      fileInputRef.current &&
      fileInputRef.current.files &&
      fileInputRef.current.files.length > 0
    ) {
      const file = fileInputRef.current.files[0];
      const content = await readFile(file);

      const dataToAdd = processData(content);

      setLoading(true);
      await addToFirestore(dataToAdd, seller);
      refreshTable();
      setLoading(false);
      toast.success("JSON Uploaded");
    }
  };

  const readFile = (file: File) => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event: ProgressEvent<FileReader>) => {
        if (event.target) {
          resolve(event.target.result as string);
        } else {
          reject(new Error("Errore durante la lettura del file."));
        }
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsText(file);
    });
  };

  const processData = (jsonContent: string) => {
    const dataToAdd: ApifyLead[] = [];
    const jsonData = JSON.parse(jsonContent);

    jsonData.forEach((item: any) => {
      if (
        item.website !== null &&
        item.website !== undefined &&
        !item.website.toLowerCase().includes("instagram")
      ) {
        const lead: ApifyLead = {
          name: item.title,
          category: item.categoryName,
          street: item.street,
          city: item.city,
          phone: item.phone,
          status: ApifyLeadStatus.pending,
          notes: "",
        };

        dataToAdd.push(lead);
      }
    });

    return dataToAdd;
  };

  const addToFirestore = async (dataToAdd: ApifyLead[], seller: Seller) => {
    const db = getFirestore();

    try {
      const userRef = doc(db, "sellers", seller.email);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        const leadsRef = collection(userRef, "leads");
        for (const data of dataToAdd) {
          await setDoc(doc(leadsRef, data.name.replaceAll("/", "")), data);
        }
      }
    } catch (e) {
      toast.error("Unable to upload JSON");
    }
  };

  const handleUploadButtonClick = (
    fileInputRef: React.RefObject<HTMLInputElement | null>
  ) => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const removeSeller = async (seller: Seller) => {};

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <Card className="h-full w-full">
        <CardHeader floated={false} shadow={false} className="rounded-none">
          <div className="mb-8 flex items-center justify-between gap-8">
            <div>
              <Typography variant="h5" color="blue-gray">
                Sellers
              </Typography>
              <Typography color="gray" className="mt-1 font-normal">
                See information about all sellers
              </Typography>
            </div>
          </div>
          <div className="w-full md:w-72">
            <Input
              crossOrigin={null}
              label="Search"
              icon={<MagnifyingGlassIcon className="h-5 w-5" />}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </CardHeader>
        <CardBody className="overflow-scroll px-0">
          <table className="mt-4 w-full min-w-max table-auto text-left">
            <thead>
              <tr>
                {TABLE_HEAD.map((head, index) => (
                  <th
                    key={index}
                    className={`cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50 ${
                      index === TABLE_HEAD.length - 1 ? "last-column" : ""
                    }`}
                    onClick={() => handleSort(head)}
                  >
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="flex items-center justify-between gap-2 font-semibold leading-none opacity-70"
                    >
                      {head}

                      {index !== TABLE_HEAD.length - 1 &&
                        index !== TABLE_HEAD.length - 2 && (
                          <ChevronUpDownIcon
                            strokeWidth={2}
                            color={`${
                              sortColumn === toCamelCase(head) ? "blue" : "grey"
                            }`}
                            className={`h-4 w-4 ${
                              sortDirection === "asc"
                                ? "transform rotate-180"
                                : ""
                            }`}
                          />
                        )}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currentSellers.map((row, index) => {
                const isLast = index === sellers.length - 1;
                const classes = isLast
                  ? "p-4"
                  : "p-4 border-b border-blue-gray-50";

                return (
                  <tr key={row.email}>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-medium"
                          >
                            {row.name} {row.surname}
                          </Typography>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal opacity-70"
                          >
                            {row.email}
                          </Typography>
                        </div>
                      </div>
                    </td>

                    <td className={classes}>
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {row.phone}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {row.registerDate}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="w-max" style={{ position: "relative" }}>
                        <Chip
                          size="sm"
                          variant="ghost"
                          value={row.leadsAssigned}
                          color="gray"
                        />
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="w-max" style={{ position: "relative" }}>
                        <Chip
                          size="sm"
                          variant="ghost"
                          value={row.pending}
                          color="yellow"
                        />
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="w-max" style={{ position: "relative" }}>
                        <Chip
                          size="sm"
                          variant="ghost"
                          value={row.accepted}
                          color="green"
                        />
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="w-max" style={{ position: "relative" }}>
                        <Chip
                          size="sm"
                          variant="ghost"
                          value={row.contactAgain}
                          color="blue"
                        />
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="w-max" style={{ position: "relative" }}>
                        <Chip
                          size="sm"
                          variant="ghost"
                          value={row.denied}
                          color="red"
                        />
                      </div>
                    </td>
                    <td className={`${classes} flex items-center`}>
                      <Tooltip content="Remove Seller">
                        <IconButton
                          variant="text"
                          onClick={() => removeSeller(row)}
                        >
                          <TrashIcon className="h-4 w-4" />
                        </IconButton>
                      </Tooltip>
                    </td>
                    <td className={classes}>
                      <div>
                        <input
                          type="file"
                          ref={fileInputRefs.current[index]}
                          style={{ display: "none" }}
                          onChange={() => {
                            handleUploadJson(row, fileInputRefs.current[index]);
                          }}
                          accept=".json"
                        />
                        <Button
                          className="flex items-center gap-3"
                          size="sm"
                          onClick={() =>
                            handleUploadButtonClick(
                              fileInputRefs.current[index]
                            )
                          }
                        >
                          <ArrowUpTrayIcon
                            strokeWidth={2}
                            className="h-4 w-4"
                          />{" "}
                          Upload CSV
                        </Button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CardBody>
        <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
          <Typography variant="small" color="blue-gray" className="font-normal">
            Page {currentPage} of{" "}
            {Math.ceil(filteredSellers.length / sellersPerPage)}
          </Typography>
          <div className="flex gap-2">
            <IconButton
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <ChevronLeftIcon className="h-4 w-4" />
            </IconButton>
            <IconButton
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={indexOfLastSeller >= filteredSellers.length}
            >
              <ChevronRightIcon className="h-4 w-4" />
            </IconButton>
          </div>
        </CardFooter>
      </Card>

      <ConfirmModal
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        onConfirm={() => {
          setConfirmDialogOpen(false);
        }}
      />
    </>
  );
}

export default SellersTable;
