import { useEffect, useState } from "react";
import { PlanStatus, WebsiteStatus } from "../../business/enums/WebsiteEnums";
import {
  ClientInterface,
  UserInterface,
  WebsiteInterface,
  WebsiteRow,
} from "../../business/models/Interfaces";
import {
  MagnifyingGlassIcon,
  ChevronUpDownIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import {
  Card,
  CardHeader,
  Input,
  Typography,
  CardBody,
  CardFooter,
  IconButton,
  Chip,
  List,
  ListItem,
  Button,
} from "@material-tailwind/react";
import { doc, collection, updateDoc, setDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { db } from "../../firebase.config";
import AddWebsiteModal from "../modals/AddWebsiteModal";

const TABLE_HEAD = [
  "Client",
  "Domain",
  "Industry",
  "Selected Plan",
  "Website Status",
  "Start Date",
];

function WebsiteTable({ users }: { users: UserInterface[] }) {
  const [websiteRows, setWebsiteRows] = useState<WebsiteRow[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [editWebsiteStatus, setEditWebsiteStatus] = useState<boolean[]>([]);
  const [addWebsiteDialogOpen, setAddWebsiteDialogOpen] =
    useState<boolean>(false);
  const websitesPerPage = 10;
  const indexOfLastWebsite = currentPage * websitesPerPage;
  const indexOfFirstWebsite = indexOfLastWebsite - websitesPerPage;

  useEffect(() => {
    createRows();
    setEditWebsiteStatus(new Array(websiteRows.length).fill(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createRows = () => {
    let websiteRows: WebsiteRow[] = [];
    users.forEach((user) => {
      user.websites.forEach((website) => {
        let websiteRow: WebsiteRow = {
          id: website.id ?? "",
          clientName: user.name + " " + user.surname,
          clientEmail: user.email,
          industry: website.industry,
          selectedPlan: getPlanStatus(website.selectedPlan),
          websiteStatus: getWebsiteStatus(website.websiteStatus ?? ""),
          startDate: convertDate(parseInt(website.subStartDate.toString())),
          domain: website.domain !== "" ? website.domain : "ND",
        };
        websiteRows.push(websiteRow);
      });
    });
    setWebsiteRows(websiteRows);
  };

  const getPlanStatus = (status: string): PlanStatus => {
    if (status.toLowerCase() === "annual") {
      return PlanStatus.annual;
    }
    return PlanStatus.monthly;
  };

  const getWebsiteStatus = (status: string): WebsiteStatus => {
    if (status.toLowerCase() === "in review") {
      return WebsiteStatus.inReview;
    }
    if (status.toLowerCase() === "in progress") {
      return WebsiteStatus.inProgress;
    }
    return WebsiteStatus.done;
  };

  const filteredWebsites = searchTerm
    ? websiteRows.filter((website) =>
        `${website.domain}`.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : websiteRows;

  const currentWebsites = filteredWebsites.slice(
    indexOfFirstWebsite,
    indexOfLastWebsite
  );

  const toCamelCase = (inputString: string) => {
    return inputString
      .replace(/[-_\s]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ""))
      .replace(/^\w/, (c) => c.toLowerCase());
  };

  const handleSort = (field: string) => {
    let column = toCamelCase(field);

    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
    if (sortDirection === "asc") {
      websiteRows.sort((a, b) =>
        a[column as keyof WebsiteRow]
          ?.toString()
          .localeCompare(b[column as keyof WebsiteRow]?.toString())
      );
    } else {
      websiteRows.sort((a, b) =>
        b[column as keyof WebsiteRow]
          ?.toString()
          .localeCompare(a[column as keyof WebsiteRow]?.toString())
      );
    }
    setWebsiteRows(websiteRows);
  };

  const convertDate = (timestamp: number): string => {
    const date = new Date(timestamp * 1000);
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    return new Intl.DateTimeFormat("it-IT", options).format(date);
  };

  const websiteStatuses: WebsiteStatus[] = [
    WebsiteStatus.inReview,
    WebsiteStatus.inProgress,
    WebsiteStatus.done,
  ];

  const updateWebsiteStatus = async (
    clientEmail: string,
    status: WebsiteStatus,
    index: number
  ) => {
    try {
      let row = websiteRows[index];
      if (!row) {
        return;
      }

      const clientRef = doc(db, "clients", clientEmail ?? "");
      const websiteRef = collection(clientRef, "websites");
      const docRef = doc(websiteRef, row.id);
      await updateDoc(docRef, { websiteStatus: status });

      websiteRows[index].websiteStatus = status;

      setWebsiteRows(websiteRows);
      updateEditWebsiteStatus(index, false);
      toast.success("Lead status updated");
    } catch (error) {
      toast.error("Error while updating lead status");
    }
  };

  const updateEditWebsiteStatus = (index: number, open: boolean) => {
    setEditWebsiteStatus(new Array(websiteRows.length).fill(false));
    setEditWebsiteStatus((prevEditStatus) => {
      const newEditStatus = [...prevEditStatus];
      newEditStatus[index] = open;
      return newEditStatus;
    });
  };

  const addNewWebsite = async (
    client: ClientInterface,
    website: WebsiteInterface
  ) => {
    try {
      const clientsRef = collection(db, "clients");
      await setDoc(doc(clientsRef, client.email), client);
      const clientRef = doc(db, "clients", client.email);
      const websitesRef = collection(clientRef, "websites");
      await setDoc(doc(websitesRef, website.domain), website);
      toast.success("New website added");
      createRows();
    } catch (e) {
      toast.error("Unable to add a new website");
    }
  };

  return (
    <>
      <Card className="h-full w-full">
        <CardHeader floated={false} shadow={false} className="rounded-none">
          <div className="mb-8 flex items-center justify-between gap-8">
            <div>
              <Typography variant="h5" color="blue-gray">
                Websites list
              </Typography>
              <Typography color="gray" className="mt-1 font-normal">
                See information about all websites
              </Typography>
            </div>
            <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
              <Button
                className="flex items-center gap-3"
                size="sm"
                color="blue"
                onClick={() => setAddWebsiteDialogOpen(true)}
              >
                <PlusIcon strokeWidth={2} className="h-4 w-4" /> Add Website
              </Button>
            </div>
          </div>
          <div className="w-full md:w-72">
            <Input
              crossOrigin={null}
              label="Search"
              icon={<MagnifyingGlassIcon className="h-5 w-5" />}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </CardHeader>
        <CardBody className="overflow-scroll px-0">
          <table className="mt-4 w-full min-w-max table-auto text-left">
            <thead>
              <tr>
                {TABLE_HEAD.map((head) => (
                  <th
                    key={head}
                    className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                    onClick={() => handleSort(head)}
                  >
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="flex items-center justify-between gap-2 font-semibold leading-none opacity-70"
                    >
                      {head}

                      <ChevronUpDownIcon
                        strokeWidth={2}
                        color={`${
                          sortColumn === toCamelCase(head) ? "blue" : "grey"
                        }`}
                        className={`h-4 w-4 ${
                          sortDirection === "asc" ? "transform rotate-180" : ""
                        }`}
                      />
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currentWebsites.map((row, index) => {
                const isLast = index === users.length - 1;
                const classes = isLast
                  ? "p-4"
                  : "p-4 border-b border-blue-gray-50";

                return (
                  <tr key={row.domain}>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-medium"
                          >
                            {row.clientName}
                          </Typography>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal opacity-70"
                          >
                            {row.clientEmail}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {row.domain}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {row.industry}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="w-max">
                        <Chip
                          size="sm"
                          variant="ghost"
                          value={row.selectedPlan}
                          color={
                            row.selectedPlan === PlanStatus.annual
                              ? "amber"
                              : "gray"
                          }
                        />
                      </div>
                    </td>

                    <td className={classes}>
                      <div className="w-max" style={{ position: "relative" }}>
                        <Chip
                          size="sm"
                          variant="ghost"
                          value={row.websiteStatus}
                          color={
                            row.websiteStatus === WebsiteStatus.inReview
                              ? "yellow"
                              : row.websiteStatus === WebsiteStatus.inProgress
                              ? "blue"
                              : "green"
                          }
                          icon={
                            editWebsiteStatus[index] ? (
                              <ChevronUpIcon />
                            ) : (
                              <ChevronDownIcon />
                            )
                          }
                          onTap={() =>
                            updateEditWebsiteStatus(
                              index,
                              !editWebsiteStatus[index]
                            )
                          }
                        />
                        {editWebsiteStatus[index] === true && (
                          <Card
                            color="white"
                            className="w-fit"
                            style={{
                              position: "absolute",
                              top: "100%",
                              left: 0,
                              zIndex: "9999",
                            }}
                          >
                            <List className="w-fit min-w-fit">
                              {websiteStatuses
                                .filter(
                                  (status) => row.websiteStatus !== status
                                )
                                .map((status) => (
                                  <ListItem
                                    className="w-fit p-0 my-1"
                                    key={status}
                                  >
                                    {row.websiteStatus !== status && (
                                      <Chip
                                        size="sm"
                                        variant="ghost"
                                        value={status}
                                        color={
                                          status === WebsiteStatus.inReview
                                            ? "yellow"
                                            : status ===
                                              WebsiteStatus.inProgress
                                            ? "blue"
                                            : "green"
                                        }
                                        onTap={() =>
                                          updateWebsiteStatus(
                                            row.clientEmail,
                                            status,
                                            index
                                          )
                                        }
                                      />
                                    )}
                                  </ListItem>
                                ))}
                            </List>
                          </Card>
                        )}
                      </div>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {row.startDate}
                      </Typography>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CardBody>
        <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
          <Typography variant="small" color="blue-gray" className="font-normal">
            Page {currentPage} of{" "}
            {Math.ceil(filteredWebsites.length / websitesPerPage)}
          </Typography>
          <div className="flex gap-2">
            <IconButton
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <ChevronLeftIcon className="h-4 w-4" />
            </IconButton>
            <IconButton
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={indexOfLastWebsite >= filteredWebsites.length}
            >
              <ChevronRightIcon className="h-4 w-4" />
            </IconButton>
          </div>
        </CardFooter>
      </Card>
      <AddWebsiteModal
        open={addWebsiteDialogOpen}
        onClose={() => setAddWebsiteDialogOpen(false)}
        onConfirm={(client: ClientInterface, website: WebsiteInterface) => {
          addNewWebsite(client, website);
          setAddWebsiteDialogOpen(false);
        }}
      />
    </>
  );
}

export default WebsiteTable;
