import { useEffect, useState } from "react";
import { deleteDoc, collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase.config";
import { toast } from "react-toastify";
import { ApifyLeadStatus } from "../../business/enums/WebsiteEnums";
import { ApifyLeadRow, Seller } from "../../business/models/Interfaces";
import {
  MagnifyingGlassIcon,
  ChevronUpDownIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import {
  Card,
  CardHeader,
  Input,
  Typography,
  CardBody,
  CardFooter,
  IconButton,
  Chip,
  Button,
} from "@material-tailwind/react";

import ConfirmModal from "../modals/ConfirmModal";

const TABLE_HEAD = ["Seller", "Name", "Street", "Category", "Phone", "Status"];

function ApifyLeadsTable({
  sellers,
  refreshTable,
}: {
  sellers: Seller[];
  refreshTable: () => void;
}) {
  const [leadRows, setLeadRows] = useState<ApifyLeadRow[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const leadsPerPage = 10;
  const indexOfLastLead = currentPage * leadsPerPage;
  const indexOfFirstLead = indexOfLastLead - leadsPerPage;

  useEffect(() => {
    createRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellers]);

  const createRows = () => {
    let leadRows: ApifyLeadRow[] = [];
    sellers.forEach((seller) => {
      seller.leads.forEach((lead) => {
        let leadRow: ApifyLeadRow = {
          sellerName: seller.name + " " + seller.surname,
          sellerEmail: seller.email,
          name: lead.name,
          category: lead.category,
          street: lead.street,
          phone: lead.phone,
          status: lead.status,
          notes: lead.notes,
        };
        leadRows.push(leadRow);
      });
    });
    setLeadRows(leadRows);
  };

  const filteredApifyLeads = searchTerm
    ? leadRows.filter((lead) =>
        `${lead.sellerName}`.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : leadRows;

  const currentApifyLeads = filteredApifyLeads.slice(
    indexOfFirstLead,
    indexOfLastLead
  );

  const toCamelCase = (inputString: string) => {
    return inputString
      .replace(/[-_\s]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ""))
      .replace(/^\w/, (c) => c.toLowerCase());
  };

  const handleSort = (field: string) => {
    let column = toCamelCase(field);

    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
    if (sortDirection === "asc") {
      leadRows.sort((a, b) =>
        a[column as keyof ApifyLeadRow]
          ?.toString()
          .localeCompare(b[column as keyof ApifyLeadRow]?.toString())
      );
    } else {
      leadRows.sort((a, b) =>
        b[column as keyof ApifyLeadRow]
          ?.toString()
          .localeCompare(a[column as keyof ApifyLeadRow]?.toString())
      );
    }
    setLeadRows(leadRows);
  };

  const deleteAllDenied = async () => {
    try {
      const deniedLeads = leadRows.filter(
        (lead) => lead.status === ApifyLeadStatus.denied
      );

      // Itera attraverso ogni documento nella collezione "sellers"
      for (const seller of sellers) {
        const leadsCollectionRef = collection(
          db,
          "sellers",
          seller.email,
          "leads"
        );

        // Ottieni tutti i documenti nella collezione "leads"
        const leadsSnapshot = await getDocs(leadsCollectionRef);
        const leadsDocs = leadsSnapshot.docs;

        // Trova e elimina i documenti nella collezione "leads" che corrispondono a quelli in deniedLeads
        const deletePromises = leadsDocs
          .filter((leadDoc) =>
            deniedLeads.some((deniedLead) => deniedLead.name === leadDoc.id)
          )
          .map(async (leadDoc) => {
            await deleteDoc(leadDoc.ref);
          });

        await Promise.all(deletePromises);
      }

      refreshTable();
      toast.success("Denied leads deleted");
    } catch (error) {
      toast.error("Error while deleting denied leads");
    }
  };

  return (
    <>
      <Card className="h-full w-full">
        <CardHeader floated={false} shadow={false} className="rounded-none">
          <div className="mb-8 flex items-center justify-between gap-8">
            <div>
              <Typography variant="h5" color="blue-gray">
                Apify leads
              </Typography>
              <Typography color="gray" className="mt-1 font-normal">
                See information about all leads
              </Typography>
            </div>
            <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
              <Button
                className="flex items-center gap-3"
                size="sm"
                color="red"
                onClick={() => setConfirmDialogOpen(true)}
              >
                <TrashIcon strokeWidth={2} className="h-4 w-4" /> Delete Denied
              </Button>
            </div>
          </div>
          <div className="w-full md:w-72">
            <Input
              crossOrigin={null}
              label="Search"
              icon={<MagnifyingGlassIcon className="h-5 w-5" />}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </CardHeader>
        <CardBody className="overflow-scroll px-0">
          <table className="mt-4 w-full min-w-max table-auto text-left">
            <thead>
              <tr>
                {TABLE_HEAD.map((head, index) => (
                  <th
                    key={head}
                    className={`cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50 ${
                      index === TABLE_HEAD.length - 1 ? "last-column" : ""
                    }`}
                    onClick={() => handleSort(head)}
                  >
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="flex items-center justify-between gap-2 font-semibold leading-none opacity-70"
                    >
                      {head}

                      {
                        <ChevronUpDownIcon
                          strokeWidth={2}
                          color={`${
                            sortColumn === toCamelCase(head) ? "blue" : "grey"
                          }`}
                          className={`h-4 w-4 ${
                            sortDirection === "asc"
                              ? "transform rotate-180"
                              : ""
                          }`}
                        />
                      }
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currentApifyLeads.map((row, index) => {
                const isLast = index === leadRows.length - 1;
                const classes = isLast
                  ? "p-4"
                  : "p-4 border-b border-blue-gray-50";

                return (
                  <tr key={row.name}>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-medium"
                          >
                            {row.sellerName}
                          </Typography>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal opacity-70"
                          >
                            {row.sellerEmail}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-medium"
                          >
                            {row.name}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {row.street}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {row.category}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {row.phone}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="w-max" style={{ position: "relative" }}>
                        <Chip
                          size="sm"
                          variant="ghost"
                          value={row.status}
                          color={
                            row.status === ApifyLeadStatus.pending
                              ? "amber"
                              : row.status === ApifyLeadStatus.contactAgain
                              ? "blue"
                              : row.status === ApifyLeadStatus.denied
                              ? "red"
                              : "green"
                          }
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CardBody>
        <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
          <Typography variant="small" color="blue-gray" className="font-normal">
            Page {currentPage} of{" "}
            {Math.ceil(filteredApifyLeads.length / leadsPerPage)}
          </Typography>
          <div className="flex gap-2">
            <IconButton
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <ChevronLeftIcon className="h-4 w-4" />
            </IconButton>
            <IconButton
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={indexOfLastLead >= filteredApifyLeads.length}
            >
              <ChevronRightIcon className="h-4 w-4" />
            </IconButton>
          </div>
        </CardFooter>
      </Card>
      <ConfirmModal
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        onConfirm={() => {
          setConfirmDialogOpen(false);
          deleteAllDenied();
        }}
      />
    </>
  );
}

export default ApifyLeadsTable;
