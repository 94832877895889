import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import leftSailor from "../../assets/images/sailor-1.svg";
import rightSailor from "../../assets/images/sailor-2.svg";

function NotFoundPage() {
  const navigate = useNavigate();
  useEffect(() => {
    navigateToHome();
  });

  const navigateToHome = async () => {
    await delay(5000);
    navigate("/websites");
  };

  const delay = (time: number) => {
    return new Promise((resolve) => setTimeout(resolve, time));
  };

  return (
    <div className="page-not-found">
      <div className="left-image">
        <img src={leftSailor} alt="sailor" />
      </div>
      <div className="right-image">
        <img src={rightSailor} alt="sailor" />
      </div>
    </div>
  );
}

export default NotFoundPage;
