import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NavList from "./NavList";
import ProfileMenu from "./ProfileMenu";
import {
  Typography,
  IconButton,
  Navbar,
  Collapse,
} from "@material-tailwind/react";
import { Bars2Icon } from "@heroicons/react/24/outline";
import useAuthStatus from "../../hooks/useAuthStatus";

function MyNavbar() {
  const navigate = useNavigate();
  const [isNavOpen, setIsNavOpen] = useState(false);
  const { userType } = useAuthStatus();
  const isAdmin = userType === "ADMIN";

  const toggleIsNavOpen = () => setIsNavOpen((cur) => !cur);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setIsNavOpen(false)
    );
  }, []);

  const goHome = () => {
    navigate("/");
  };

  return (
    <div className="py-7">
      <Navbar
        className={`mx-auto ${
          isAdmin ? "w-2/4" : "w-1/3"
        }  p-2 lg:rounded-full lg:pl-6`}
      >
        <div className="relative mx-auto flex items-center text-blue-gray-900">
          <Typography
            className="mr-4 ml-2 cursor-pointer py-1.5 font-bold hidden xsm:block"
            onClick={goHome}
          >
            GoWebb
          </Typography>
          <div className="absolute top-2/4 left-2/4 hidden -translate-x-2/4 -translate-y-2/4 lg:block">
            <NavList />
          </div>
          <IconButton
            size="sm"
            color="blue-gray"
            variant="text"
            onClick={toggleIsNavOpen}
            className="ml-auto mr-2 lg:hidden"
          >
            <Bars2Icon className="h-6 w-6" />
          </IconButton>
          <ProfileMenu />
        </div>
        <Collapse open={isNavOpen} className="overflow-scroll">
          <NavList />
        </Collapse>
      </Navbar>
    </div>
  );
}

export default MyNavbar;
