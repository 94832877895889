import { collection, query, orderBy, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { db } from "../../firebase.config";
import {
  UserInterface,
  WebsiteInterface,
} from "../../business/models/Interfaces";
import WebsiteTable from "../../components/table/WebsiteTable";
import Spinner from "../../components/Spinner";

function WebsitesPage() {
  const [users, setUsers] = useState<UserInterface[]>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const usersRef = collection(db, "clients");
      const q = query(usersRef, orderBy("surname", "desc"));
      const querySnap = await getDocs(q);
      const usersData: UserInterface[] = [];

      const promises = querySnap.docs.map(async (doc) => {
        const data = doc.data();
        const websitesRef = collection(doc.ref, "websites");
        const websitesQuerySnap = await getDocs(websitesRef);
        const websites: WebsiteInterface[] = [];

        websitesQuerySnap.forEach((websiteDoc) => {
          const websiteData = websiteDoc.data();
          websites.push({
            id: websiteDoc.id,
            industry: websiteData.industry,
            selectedPlan: websiteData.selectedPlan,
            websiteStatus: websiteData.websiteStatus,
            subStartDate: websiteData.subStartDate,
            domain: websiteData.domain,
          });
        });

        usersData.push({
          name: data.name,
          surname: data.surname,
          email: data.email,
          websites,
          isAdmin: data.isAdmin,
        });
      });

      await Promise.all(promises);

      setUsers(usersData);
      setLoading(false);
    } catch (e) {
      toast.error("Could not fetch users");
      setLoading(false);
    }
  };

  if (loading) {
    return <Spinner />;
  }
  return (
    <div className="home-section">
      <div className="home-content">
        <WebsiteTable users={users ?? []} />
      </div>
    </div>
  );
}

export default WebsitesPage;
