import { useEffect, useState } from "react";
import { ApifyLeadStatus } from "../../business/enums/WebsiteEnums";
import { ApifyLead, Seller } from "../../business/models/Interfaces";
import { collection, query, orderBy, getDocs } from "firebase/firestore";
import { db } from "../../firebase.config";
import Spinner from "../../components/Spinner";
import ApiLeadsTable from "../../components/table/ApifyLeadsTable";

function ApifyLeadsPage() {
  const [sellers, setSellers] = useState<Seller[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchApifyLeads();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchApifyLeads = async () => {
    try {
      const usersRef = collection(db, "sellers");
      const q = query(usersRef, orderBy("name", "desc"));
      const querySnap = await getDocs(q);
      const sellersData: Seller[] = [];

      const promises = querySnap.docs.map(async (doc) => {
        const data = doc.data();
        const leadsRef = collection(doc.ref, "leads");
        const leadsQuerySnap = await getDocs(leadsRef);
        const leads: ApifyLead[] = [];

        leadsQuerySnap.forEach((leadDoc) => {
          const leadData = leadDoc.data();
          leads.push({
            name: leadData.name,
            street: leadData.street,
            city: leadData.city,
            category: leadData.category,
            phone: leadData.phone,
            status: getLeadStatus(leadData.status),
            notes: leadData.notes,
          });
        });

        sellersData.push({
          name: data.name,
          surname: data.surname,
          email: data.email,
          phone: data.phone,
          registerDate: data.registerDate,
          leadsAssigned: data.leadsAssigned,
          pending: data.pending,
          accepted: data.accepted,
          contactAgain: data.contactAgain,
          denied: data.denied,
          leads,
        });
      });

      // Attendere il completamento di tutte le operazioni asincrone
      await Promise.all(promises);

      setSellers(sellersData);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const getLeadStatus = (status: string): ApifyLeadStatus => {
    if (status.toLowerCase() === "pending") {
      return ApifyLeadStatus.pending;
    }
    if (status.toLowerCase() === "contact again") {
      return ApifyLeadStatus.contactAgain;
    }
    if (status.toLowerCase() === "accepted") {
      return ApifyLeadStatus.accepted;
    }
    return ApifyLeadStatus.denied;
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="home-section">
      <div className="home-content">
        <ApiLeadsTable sellers={sellers ?? []} refreshTable={fetchApifyLeads} />
      </div>
    </div>
  );
}

export default ApifyLeadsPage;
