import { useState } from "react";
import {
  createUserWithEmailAndPassword,
  getAuth,
  signOut,
} from "firebase/auth";
import { db } from "../../firebase.config";
import logo from "../../assets/images/logo.png";
import { toast } from "react-toastify";
import { Card, CardBody, Input, Button } from "@material-tailwind/react";
import { collection, doc, setDoc } from "firebase/firestore";
import { initializeApp } from "firebase/app";

function AddSellerPage() {
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    email: "",
    phone: "",
  });
  const { name, surname, email, phone } = formData;

  const onChange = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();

    try {
      const firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
      };

      var secondaryApp = initializeApp(firebaseConfig, "Secondary");

      const auth = getAuth(secondaryApp);
      await createUserWithEmailAndPassword(auth, email, "password");
      await signOut(auth);

      let seller: any = {
        name: name,
        surname: surname,
        email: email,
        phone: phone,
        registerDate: Number(Math.floor(Date.now() / 1000)),
        leadsAssigned: 0,
        pending: 0,
        accepted: 0,
        contactAgain: 0,
        denied: 0,
      };

      const leadsRef = collection(db, "sellers");
      await setDoc(doc(leadsRef, seller.email), seller);
      toast.success("Seller registered");
      setFormData({ name: "", surname: "", email: "", phone: "" });
    } catch (error) {
      toast.error("Unable to register seller");
    }
  };

  return (
    <div className="home-section">
      <div className="home-content">
        <Card className="w-96">
          <CardBody className="flex flex-col gap-4">
            <div className="flex items-center justify-center mb-8">
              <img src={logo} width={140} alt="webbling-logo" />
            </div>
            <form>
              <div className="mb-4 flex flex-col gap-6">
                <Input
                  label="Name"
                  size="lg"
                  crossOrigin={undefined}
                  id="name"
                  value={name}
                  onChange={onChange}
                />
                <Input
                  label="Surname"
                  size="lg"
                  crossOrigin={undefined}
                  id="surname"
                  value={surname}
                  onChange={onChange}
                />
                <Input
                  label="Email"
                  size="lg"
                  crossOrigin={undefined}
                  id="email"
                  value={email}
                  onChange={onChange}
                />
                <Input
                  label="Phone"
                  size="lg"
                  crossOrigin={undefined}
                  id="phone"
                  value={phone}
                  onChange={onChange}
                />
              </div>

              <Button
                onClick={onSubmit}
                variant="filled"
                color="deep-purple"
                fullWidth
              >
                Register Seller
              </Button>
            </form>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default AddSellerPage;
