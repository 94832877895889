import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { toast, ToastItem } from "react-toastify";
import logo from "../../assets/images/logo.png";
import {
  Card,
  CardBody,
  Input,
  Button,
  Typography,
} from "@material-tailwind/react";

function ForgotPasswordPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const onChange = (e: any) => {
    setEmail(e.target.value);
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();

    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email);
      toast.success("Email sent");
      toast.onChange((payload: ToastItem) => {
        switch (payload.type) {
          case "success":
            navigate("/home");
            break;
        }
      });
    } catch (error) {
      toast.error("Could not send email");
    }
  };

  return (
    <div className="h-screen flex items-center justify-center auth-background p-10 lg:p-0">
      <Card className="w-96">
        <CardBody className="flex flex-col gap-4">
          <div className="flex items-center justify-center ">
            <img src={logo} width={140} alt="webbling-logo" />
          </div>
          <form>
            <div className="mb-6">
              <Typography
                variant="small"
                color="black"
                className="text-left mb-2"
              >
                Inserisci il tuo indirizzo email per reimpostare la password del
                tuo account. Ti invieremo le istruzioni tramite email.
              </Typography>
              <Typography
                variant="small"
                color="black"
                className="text-left mb-8"
              >
                *Assicurati di controllare la cartella dello spam se non ricevi
                l'email entro qualche minuto.
              </Typography>
              <Input
                label="Email"
                size="lg"
                crossOrigin={undefined}
                id="email"
                value={email}
                onChange={onChange}
              />
            </div>

            <Button
              onClick={onSubmit}
              variant="filled"
              color="deep-purple"
              fullWidth
            >
              Reset Password
            </Button>
          </form>
        </CardBody>
      </Card>
    </div>
  );
}

export default ForgotPasswordPage;
