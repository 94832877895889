import { useEffect, useState } from "react";
import { collection, query, getDocs, where } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { db } from "../../firebase.config";
import { ApifyLeadStatus } from "../../business/enums/WebsiteEnums";
import { ApifyLead } from "../../business/models/Interfaces";
import Spinner from "../../components/Spinner";
import MyLeadsTable from "../../components/table/MyLeadsTable";

function MyLeadsPage() {
  const [leads, setLeads] = useState<ApifyLead[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchMyLeads();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchMyLeads = async () => {
    const auth = getAuth();
    let currentUser = auth.currentUser?.email;

    try {
      const sellersRef = collection(db, "sellers");
      const q = query(sellersRef, where("email", "==", currentUser));

      const querySnap = await getDocs(q);
      if (querySnap.empty) {
        setLoading(false);
        return [];
      }

      const sellerDoc = querySnap.docs[0];
      const leadsRef = collection(sellerDoc.ref, "leads");
      const leadsQuerySnap = await getDocs(leadsRef);

      const myLeads: ApifyLead[] = [];

      const promises = leadsQuerySnap.docs.map(async (doc) => {
        const data = doc.data();
        myLeads.push({
          name: data.name,
          street: data.street,
          city: data.city,
          category: data.category,
          phone: data.phone,
          status: getLeadStatus(data.status),
          notes: data.notes,
        });
      });
      await Promise.all(promises);

      setLeads(myLeads);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const getLeadStatus = (status: string): ApifyLeadStatus => {
    if (status.toLowerCase() === "pending") {
      return ApifyLeadStatus.pending;
    }
    if (status.toLowerCase() === "contact again") {
      return ApifyLeadStatus.contactAgain;
    }
    if (status.toLowerCase() === "accepted") {
      return ApifyLeadStatus.accepted;
    }
    return ApifyLeadStatus.denied;
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="home-section">
      <div className="home-content">
        <MyLeadsTable apifyLeads={leads ?? []} refreshTable={fetchMyLeads} />
      </div>
    </div>
  );
}

export default MyLeadsPage;
