import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../../firebase.config";
import logo from "../../assets/images/logo.png";
import { toast } from "react-toastify";
import {
  Card,
  CardBody,
  Typography,
  Input,
  Button,
} from "@material-tailwind/react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { UserType } from "../../business/enums/UserType";

function SignInPage() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: "admin@gmail.com",
    password: "password",
  });
  const { email, password } = formData;

  const onChange = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();

    try {
      const userCredentials = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      if (userCredentials.user) {
        const userType = await getUserType(userCredentials.user.email ?? "");
        if (userType === "ADMIN") {
          navigate("/websites");
        } else if (userType === "SELLER") {
          navigate("/myLeads");
        } else {
          auth.signOut();
          toast.error("Not Authorized");
        }
      }
    } catch (error) {
      toast.error("Bad User Credentials");
    }
  };

  const getUserType = async (currentEmail: string): Promise<UserType> => {
    const adminsRef = collection(db, "admins");
    const adminQuery = query(adminsRef, where("email", "==", currentEmail));
    const adminQuerySnap = await getDocs(adminQuery);
    if (!adminQuerySnap.empty) {
      return UserType.ADMIN;
    }
    return UserType.SELLER;
  };

  return (
    <div className="h-screen flex items-center justify-center auth-background p-10 lg:p-0">
      <Card className="w-96">
        <CardBody className="flex flex-col gap-4">
          <div className="flex items-center justify-center mb-8">
            <img src={logo} width={140} alt="webbling-logo" />
          </div>
          <form>
            <div className="mb-4 flex flex-col gap-6">
              <Input
                label="Email"
                size="lg"
                crossOrigin={undefined}
                id="email"
                value={email}
                onChange={onChange}
              />
              <Input
                label="Password"
                size="lg"
                crossOrigin={undefined}
                id="password"
                type="password"
                value={password}
                onChange={onChange}
              />
              <Typography variant="small" color="black" className="text-right">
                <Link to={"/forgot-password"}>Forgot Password?</Link>
              </Typography>
            </div>

            <Button
              onClick={onSubmit}
              variant="filled"
              color="teal"
              fullWidth
            >
              Sign In
            </Button>
          </form>
        </CardBody>
      </Card>
    </div>
  );
}

export default SignInPage;
