import { createElement, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import {
  Typography,
  Button,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Avatar,
} from "@material-tailwind/react";
import {
  UserCircleIcon,
  ChevronDownIcon,
  PowerIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline";
import useAuthStatus from "../../hooks/useAuthStatus";

const profileMenuItemsAdmin = [
  {
    label: "My Profile",
    icon: UserCircleIcon,
    link: "/myProfile",
  },

  {
    label: "Add Seller",
    icon: UserPlusIcon,
    link: "/addSeller",
  },
  {
    label: "Sign Out",
    icon: PowerIcon,
    link: "/sign-in",
  },
];

const profileMenuItemsSeller = [
  {
    label: "My Profile",
    icon: UserCircleIcon,
    link: "/myProfile",
  },
  {
    label: "Sign Out",
    icon: PowerIcon,
    link: "/sign-in",
  },
];

function ProfileMenu() {
  const auth = getAuth();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { userType } = useAuthStatus();

  const profileMenuItems =
    userType === "ADMIN" ? profileMenuItemsAdmin : profileMenuItemsSeller;

  const onLogout = () => {
    auth.signOut();
    navigate("/sign-in");
  };

  return (
    <Menu open={isMenuOpen} handler={setIsMenuOpen} placement="bottom-end">
      <MenuHandler>
        <Button
          variant="text"
          color="blue-gray"
          className="flex items-center gap-1 rounded-full py-0.5 pr-2 pl-0.5 lg:ml-auto"
        >
          <Avatar
            variant="circular"
            size="sm"
            alt="tania andrew"
            className="border border-gray-900 p-0.5"
            src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80"
          />
          <ChevronDownIcon
            strokeWidth={2.5}
            className={`h-3 w-3 transition-transform ${
              isMenuOpen ? "rotate-180" : ""
            }`}
          />
        </Button>
      </MenuHandler>
      <MenuList className="p-1">
        {profileMenuItems.map(({ label, icon, link }, key) => {
          const isLastItem = key === profileMenuItems.length - 1;
          return (
            <MenuItem
              key={label}
              onClick={isLastItem ? onLogout : () => navigate(link)}
              className={`flex items-center gap-2 rounded ${
                isLastItem
                  ? "hover:bg-red-500/10 focus:bg-red-500/10 active:bg-red-500/10"
                  : ""
              }`}
            >
              {createElement(icon, {
                className: `h-4 w-4 ${isLastItem ? "text-red-500" : ""}`,
                strokeWidth: 2,
              })}
              <Typography
                as="span"
                variant="small"
                className="font-normal"
                color={isLastItem ? "red" : "inherit"}
              >
                {label}
              </Typography>
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
}

export default ProfileMenu;
