import { useNavigate } from "react-router-dom";
import { createElement } from "react";
import { Typography, MenuItem } from "@material-tailwind/react";
import {
  GlobeAltIcon,
  BuildingStorefrontIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import useAuthStatus from "../../hooks/useAuthStatus";

const navListItemsAdmin = [
  {
    label: "Websites",
    icon: GlobeAltIcon,
    link: "/websites",
  },
  {
    label: "Apify Leads",
    icon: BuildingStorefrontIcon,
    link: "/apifyLeads",
  },
  {
    label: "Sellers",
    icon: UserGroupIcon,
    link: "/sellers",
  },
];

const navListItemsSeller = [
  {
    label: "My Leads",
    icon: BuildingStorefrontIcon,
    link: "/myLeads",
  },
];

function NavList() {
  const navigate = useNavigate();
  const { userType } = useAuthStatus();

  const navListItems =
    userType === "ADMIN" ? navListItemsAdmin : navListItemsSeller;

  return (
    <ul className="mb-4 mt-2 flex flex-col gap-6 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center">
      {navListItems.map(({ label, icon, link }, key) => (
        <Typography
          onClick={() => navigate(link)}
          key={key}
          as="a"
          variant="small"
          color="blue-gray"
          className="font-normal"
        >
          <MenuItem className="flex items-center gap-2 lg:rounded-full">
            {createElement(icon, { className: "h-[18px] w-[18px]" })} {label}
          </MenuItem>
        </Typography>
      ))}
    </ul>
  );
}

export default NavList;
