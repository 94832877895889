export enum WebsiteStatus {
  inReview = "In Review",
  inProgress = "In Progress",
  done = "Done",
}

export enum PaymentStatus {
  paid = "Paid",
  pending = "Pending",
}

export enum PlanStatus {
  annual = "Annual",
  monthly = "Monthly",
}

export enum WebsiteType {
  essential = "Essential",
  advanced = "Advanced",
}

export enum ApifyLeadStatus {
  pending = "Pending",
  contactAgain = "Contact again",
  accepted = "Accepted",
  denied = "Denied",
}
