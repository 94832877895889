import { Navigate, Outlet } from "react-router-dom";
import useAuthStatus from "../hooks/useAuthStatus";
import Spinner from "./Spinner";
import { UserType } from "../business/enums/UserType";

function PrivateRoute({ allowedUserTypes }: { allowedUserTypes: UserType[] }) {
  const { loggedIn, checkingStatus, userType } = useAuthStatus();

  if (checkingStatus) {
    return <Spinner />;
  }

  // Verifica se l'utente è autenticato
  if (!loggedIn) {
    return <Navigate to="/sign-in" />;
  }

  // Verifica se il tipo di utente è consentito per l'accesso alla pagina
  if (allowedUserTypes.includes(userType ?? UserType.ADMIN)) {
    return <Outlet />;
  } else {
    // Reindirizza a una pagina di accesso negato o esegui altre azioni
    return <Navigate to="/" />;
  }
}

export default PrivateRoute;
